"use strict";

$(document).ready(function ($) {
  var page = 1;
  var selectedFilters = [];
  function loadProducts(page) {
    var brands = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var types = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    $('.load-more').addClass('loading');
    $('.filter-results').addClass('loading');
    $.ajax({
      url: myAjax.ajaxurl,
      type: 'POST',
      data: {
        action: 'load_products',
        page: page,
        brands: brands,
        types: types,
        term_id: myAjax.term_id
      },
      success: function success(response) {
        $('.load-more').removeClass('loading');
        $('.filter-results').removeClass('loading');
        if (response.html) {
          $('.filter-results').append(response.html);
          page++;
          if (!response.has_more) {
            $('.load-more').hide();
          } else {
            $('.load-more').show();
          }
        } else {
          $('.load-more').hide();
        }
      },
      error: function error(xhr, status, _error) {
        $('.load-more').removeClass('loading');
        console.error("\u041E\u0448\u0438\u0431\u043A\u0430: ".concat(status, ", ").concat(_error));
      }
    });
  }
  function getSelectedFilters() {
    var brands = [];
    var types = [];
    $('.taxonomy-filter input[name="brand_filter[]"]:checked').each(function () {
      brands.push($(this).val());
    });
    $('.taxonomy-filter input[name="product_type_filter[]"]:checked').each(function () {
      types.push($(this).val());
    });
    types = types.filter(function (type) {
      var $checkbox = $('.taxonomy-filter input[type="checkbox"][value="' + type + '"]');

      // Если это дочерний элемент, оставляем его
      if ($checkbox.hasClass('child-checkbox')) {
        return true;
      }

      // Если это родительский элемент
      if ($checkbox.hasClass('parent-checkbox')) {
        // Ищем активные дочерние элементы в рамках родительского чекбокса
        var hasActiveChild = $checkbox.closest('li').find('.child-categories .child-checkbox:checked').length > 0;

        // Если у этого родителя есть активные дочерние, исключаем его
        if (hasActiveChild) {
          return false;
        }
      }

      // Оставляем родительский элемент, если у него нет активных дочерних
      return true;
    });
    return {
      brands: brands,
      types: types
    };
  }

  // Удаляем предыдущий обработчик перед добавлением нового
  $('.taxonomy-filter input[type="checkbox"]').off('change').on('change', debounce(function () {
    updateSelectedFilters();
    $('.filter-results').empty();
    page = 1;
    var _getSelectedFilters = getSelectedFilters(),
      brands = _getSelectedFilters.brands,
      types = _getSelectedFilters.types;
    loadProducts(page, brands, types);
    toggleFilterShowButton(); // Проверяем видимость кнопки
  }, 300));
  $('.load-more').off('click').on('click', function () {
    page++;
    var _getSelectedFilters2 = getSelectedFilters(),
      brands = _getSelectedFilters2.brands,
      types = _getSelectedFilters2.types;
    loadProducts(page, brands, types);
  });
  $('.filter-btn').on('click', function () {
    if ($(window).width() < 768) {
      // $('.filters-wrapper').slideToggle();
      $('.filters-wrapper').addClass('active');
      $('html, body').animate({
        scrollTop: $('.filters-wrapper').offset().top
      }, 'slow');
    }
  });
  $('.filter-close').on('click', function () {
    if ($(window).width() < 768) {
      // $('.filters-wrapper').slideUp();
      $('.filters-wrapper').removeClass('active');
    }
  });
  $('.filter-show').on('click', function () {
    if ($(window).width() < 768) {
      // $('.filters-wrapper').slideUp();
      $('.filters-wrapper').removeClass(' active');
    }
  });
  var filterBtn = document.querySelector('.filter-btn');
  var listCheckboxes = document.querySelector('.list-checkboxes');
  function updateSelectedFilters() {
    listCheckboxes.innerHTML = ''; // Очищаем текущий список выбранных фильтров

    selectedFilters.forEach(function (filter) {
      var filterItem = document.createElement('button');
      filterItem.classList.add('selected-filter-btn');
      filterItem.textContent = filter;
      filterItem.addEventListener('click', function () {
        removeFilter(filter);
      });
      listCheckboxes.appendChild(filterItem);
    });
    var removeAllBtn = document.createElement('button');
    removeAllBtn.classList.add('p2', 'blue', 'remove-all-btn');
    removeAllBtn.textContent = 'Видалити все';
    if (selectedFilters.length > 0) {
      listCheckboxes.appendChild(removeAllBtn);
    }
  }
  function addFilter(filter) {
    if (!selectedFilters.includes(filter)) {
      selectedFilters.push(filter);
      updateSelectedFilters();
      updateFilterBtnCount();
    }
  }
  function removeFilter(filter) {
    selectedFilters = selectedFilters.filter(function (item) {
      return item !== filter;
    });
    updateSelectedFilters();
    updateFilterBtnCount();
    $('.taxonomy-filter input[type="checkbox"]').each(function () {
      var label = $(this).closest('label');
      var filterName = label.find('span').first().text();
      if (filterName === filter) {
        $(this).prop('checked', false);
      }
    });
    toggleFilterShowButton(); // Проверяем видимость кнопки
  }

  $(document).on('click', '.selected-filter-btn', function () {
    var filterName = $(this).text();
    removeFilter(filterName);
  });
  function updateFilterBtnCount() {
    var countSpan = filterBtn.querySelector('span');
    if (selectedFilters.length > 0) {
      countSpan.classList.add('active');
    } else {
      countSpan.classList.remove('active');
    }
    countSpan.textContent = selectedFilters.length ? "".concat(selectedFilters.length) : '';
  }
  $(document).on('click', '.remove-all-btn', function () {
    selectedFilters = [];
    $('.taxonomy-filter input[type="checkbox"]').prop('checked', false);
    updateSelectedFilters();
    updateFilterBtnCount();
    $('.filter-results').empty();
    page = 1;
    var _getSelectedFilters3 = getSelectedFilters(),
      brands = _getSelectedFilters3.brands,
      types = _getSelectedFilters3.types;
    loadProducts(page, brands, types);
  });
  $('.taxonomy-filter input[type="checkbox"]').on('change', function () {
    var label = $(this).closest('label');
    var filterName = label.find('span').first().text();
    if (this.checked) {
      addFilter(filterName);
    } else {
      removeFilter(filterName);
    }
    $('.filter-results').empty();
    page = 1;
    var _getSelectedFilters4 = getSelectedFilters(),
      brands = _getSelectedFilters4.brands,
      types = _getSelectedFilters4.types;
    // loadProducts(page, brands, types);
    toggleFilterShowButton(); // Проверяем видимость кнопки
  });

  function toggleFilterShowButton() {
    if ($(window).width() < 768 && selectedFilters.length > 0) {
      $('.filter-show').show(); // Показываем кнопку
    } else {
      $('.filter-show').hide(); // Скрываем кнопку
    }
  }

  function debounce(func, delay) {
    var timer;
    return function () {
      var _arguments = arguments,
        _this = this;
      clearTimeout(timer);
      timer = setTimeout(function () {
        func.apply(_this, _arguments);
      }, delay);
    };
  }
  $('.filters-wrapper').on('change', 'input[type="checkbox"]', function () {
    var brands = [];
    var types = [];
    $('input[name="brand_filter[]"]:checked').each(function () {
      brands.push($(this).val());
    });
    $('input[name="product_type_filter[]"]:checked').each(function () {
      types.push($(this).val());
    });
    types = types.filter(function (type) {
      var $checkbox = $('.taxonomy-filter input[type="checkbox"][value="' + type + '"]');

      // Если это дочерний элемент, оставляем его
      if ($checkbox.hasClass('child-checkbox')) {
        return true;
      }

      // Если это родительский элемент
      if ($checkbox.hasClass('parent-checkbox')) {
        // Ищем активные дочерние элементы в рамках родительского чекбокса
        var hasActiveChild = $checkbox.closest('li').find('.child-categories .child-checkbox:checked').length > 0;

        // Если у этого родителя есть активные дочерние, исключаем его
        if (hasActiveChild) {
          return false;
        }
      }

      // Оставляем родительский элемент, если у него нет активных дочерних
      return true;
    });
    $.ajax({
      url: myAjax.ajaxurl,
      type: 'POST',
      data: {
        action: 'my_filter_posts',
        brands: brands,
        types: types,
        term_id: myAjax.term_id
      },
      success: function success(response) {
        if (response.success) {
          $('.filter-section .section-header span.p2').text("\u041F\u043E\u0437\u0438\u0446\u0456\u0439 \u0437\u043D\u0430\u0439\u0434\u0435\u043D\u043E: ".concat(response.data.found_posts)); // Обновить количество постов
        }
      }
    });
  });

  document.querySelectorAll('.child-checkbox').forEach(function (childCheckbox) {
    childCheckbox.addEventListener('change', function () {
      var parentCheckbox = this.closest('ul').previousElementSibling.querySelector('.parent-checkbox');
      if (this.checked) {
        parentCheckbox.checked = true;
      }
    });
  });
  document.querySelectorAll('.parent-checkbox').forEach(function (parentCheckbox) {
    parentCheckbox.addEventListener('change', function () {
      if (!this.checked) {
        var childCheckboxes = this.closest('li').querySelectorAll('.child-checkbox');
        childCheckboxes.forEach(function (childCheckbox) {
          childCheckbox.checked = false;
        });
      }
    });
  });
  $('.parent-checkbox').change(function () {
    if ($(this).is(':checked')) {
      $(this).closest('li').find('.child-categories').slideDown();
    } else {
      $(this).closest('li').find('.child-categories').slideUp();
    }
  });

  // loadProducts();

  $('.taxonomy-filter-top').off('click').on('click', function () {
    $(this).next('.taxonomy-filter-bottom').slideToggle();
    $(this).toggleClass('active');
  });
});
function header() {
  menuDropdownsHandler();
  mobileMenuToggle();
}
function menuDropdownsHandler() {
  if (window.innerWidth > 990) {
    var dropdownItems = document.querySelectorAll('.header-dt-menu .menu-item');
    var firstLevelMenuItems = document.querySelectorAll('.header-dt-menu > .menu-item');
    if (dropdownItems.length !== 0) {
      dropdownItems.forEach(function (dropdown) {
        dropdown.addEventListener('mouseenter', openSubMenu);
      });
    }
    if (firstLevelMenuItems.length !== 0) {
      firstLevelMenuItems.forEach(function (menuItem) {
        menuItem.addEventListener('mouseleave', closeSubMenu);
      });
    }
  } else {
    var _dropdownItems = document.querySelectorAll('.header-dt-menu .menu-item-has-children');
    if (_dropdownItems.length !== 0) {
      _dropdownItems.forEach(function (dropdownItem) {
        dropdownItem.addEventListener('click', function (event) {
          var parentMenuItem = event.target.closest('.menu-item-has-children');
          var subMenu = parentMenuItem.querySelector('.sub-menu');
          if (subMenu && event.target.classList.contains('menu-item-has-children')) {
            subMenu.classList.toggle('visible');
            parentMenuItem.classList.toggle('sub-menu-is-visible');
          }
        });
      });
    }
  }
}
function openSubMenu(event) {
  var subMenu = event.target.querySelector('.sub-menu');
  if (subMenu) {
    subMenu.classList.add('visible');
  }
  event.stopPropagation();
}
function closeSubMenu(event) {
  var subMenus = event.target.querySelectorAll('.sub-menu');
  if (subMenus.length !== 0) {
    subMenus.forEach(function (subMenu) {
      subMenu.classList.remove('visible');
    });
  }
  event.stopPropagation();
}
function mobileMenuToggle() {
  var mobMenuToggle = document.querySelector('.mobile-menu-toggle');
  var headerMobNav = document.querySelector('.header-nav-wrapper');
  if (mobMenuToggle) {
    mobMenuToggle.addEventListener('click', function (e) {
      $(mobMenuToggle).toggleClass('open');
      $(headerMobNav).toggleClass('open');
      document.body.classList.toggle('no-scroll');
    });
  }
}
var reviewsGalleryHandler = function reviewsGalleryHandler() {
  var togglers = document.querySelectorAll('[data-review-toggle]');
  if (togglers.length === 0) {
    return;
  }
  togglers.forEach(function (toggle) {
    toggle.addEventListener('click', showReviewsModalGallery);
  });
  var btnClose = document.querySelector('[data-gallery-container] .modal-gallery-container .btn-close');
  if (!btnClose) {
    return;
  }
  btnClose.addEventListener('click', hideReviewsModalGallery);
};
var showReviewsModalGallery = function showReviewsModalGallery(event) {
  var modalGallery = event.target.closest('[data-gallery-container]').querySelector('.modal-gallery-container');
  var currentSlideIndex = 0;
  if (!modalGallery) {
    return;
  }
  if (event.target.closest('[data-review-toggle]')) {
    modalGallery.classList.add('visible');
    document.querySelector('body').classList.add('scroll-lock');

    //set current slide

    currentSlideIndex = event.target.closest('.slick-slide').dataset.slickIndex;
    $('[data-modal-gallery]').slick('slickGoTo', currentSlideIndex);
  }
};
var hideReviewsModalGallery = function hideReviewsModalGallery(event) {
  var modalGallery = event.target.closest('[data-gallery-container]').querySelector('.modal-gallery-container');
  if (!modalGallery) {
    return;
  }
  modalGallery.classList.remove('visible');
  document.querySelector('body').classList.remove('scroll-lock');
};
var modalGallerySlider = function modalGallerySlider() {
  var slider = document.querySelector("[data-modal-gallery]");
  if (!slider) {
    return;
  }
  var clients = slider.querySelectorAll('.client-slide');
  if (clients.length > 1) {
    var slickOptions = {
      dots: false,
      slidesToShow: 1,
      infinite: true,
      arrows: false,
      variableWidth: false,
      adaptiveHeight: false,
      fade: true,
      cssEase: 'linear',
      responsive: [{
        breakpoint: 1025,
        settings: {
          dots: true
        }
      }]
    };
    $(slider).slick(slickOptions);
    $("[data-modal-gallery-nav] .slide-prev").click(function (e) {
      $(slider).slick("slickPrev");
    });
    $("[data-modal-gallery-nav] .slide-next").click(function (e) {
      $(slider).slick("slickNext");
    });
  }
};
var clientsSlider = function clientsSlider() {
  var SLIDER_BREAKPOINT = 769;
  var NAV_BREAKPOINT = 1025;
  var clientsSlider = document.querySelector("[data-clients-slider]");
  if (!clientsSlider) {
    return;
  }
  var clients = clientsSlider.querySelectorAll('.client-slide');
  var slickOptions = {
    centerMode: false,
    slidesToShow: 2,
    initialSlide: 0,
    infinite: true,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: SLIDER_BREAKPOINT,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        dots: true
      }
    }]
  };
  if (clients.length > 2 || clients.length > 1 && window.matchMedia("(max-width: ".concat(SLIDER_BREAKPOINT, "px)")).matches) {
    $(clientsSlider).slick(slickOptions);
    var clientsNav = document.querySelector('[data-clients-nav]');
    if (clientsNav && window.matchMedia("(min-width: ".concat(NAV_BREAKPOINT, "px)")).matches) {
      clientsNav.style.display = 'block';
    }
  }
  $(".clients-nav .slide-prev").click(function (e) {
    $(clientsSlider).slick("slickPrev");
  });
  $(".clients-nav .slide-next").click(function (e) {
    $(clientsSlider).slick("slickNext");
  });
};
clientsSlider();
modalGallerySlider();
window.addEventListener('load', function () {
  header();
  slidersHandler();
  reviewsGalleryHandler();
});

// ---------------  SLIDERS --------------------

function slidersHandler() {
  marquee();
  ProductSlider();
  ScrollThumbnails();
  GalleryPopup();
}
function marquee() {
  var marqueeElementsDt = document.querySelectorAll("[data-marquee-dt]");
  var marqueeElementsMob = document.querySelectorAll("[data-marquee-mob]");
  if (marqueeElementsDt.length !== 0) {
    marqueeElementsDt.forEach(function (el) {
      return animateMarquee(el, 60000);
    });
  }
  if (marqueeElementsMob.length !== 0) {
    marqueeElementsMob.forEach(function (el) {
      return animateMarquee(el, 30000);
    });
  }
}
function animateMarquee(el, duration) {
  console.log('111');
  var innerEl = el.querySelector(".marquee-inner");
  console.log('ele ', innerEl);
  var innerWidth = innerEl.offsetWidth;
  var cloneEl = innerEl.cloneNode(true);
  el.appendChild(cloneEl);
  var start = performance.now();
  var progress;
  var translateX;
  requestAnimationFrame(function step(now) {
    progress = (now - start) / duration;
    if (progress > 1) {
      progress %= 1;
      start = now;
    }
    translateX = innerWidth * progress;
    innerEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
    cloneEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
    requestAnimationFrame(step);
  });
}
function ProductSlider() {
  var images = [];
  $('#thumbnails-wrapper img').each(function () {
    images.push($(this).attr('src'));
  });
  var currentIndex = 0;
  function createDots() {
    var dotsContainer = $('.nav-dots');
    dotsContainer.empty();
    images.forEach(function (_, index) {
      dotsContainer.append("<div class=\"nav-dot\" data-index=\"".concat(index, "\"></div>"));
    });
    $('.nav-dot').eq(currentIndex).addClass('active');
  }
  function updateDots(index) {
    $('.nav-dot').removeClass('active');
    $('.nav-dot').eq(index).addClass('active');
  }
  function updateFraction(index) {
    var fractionText = "".concat(index + 1, "/").concat(images.length);
    $('.nav-fraction').text(fractionText);
  }
  function scrollToActiveThumbnail(wrapperSelector, containerSelector) {
    var activeThumbnail = $(wrapperSelector + ' .slider-item.active');
    var thumbnailsContainer = $(containerSelector);
    var containerWidth = thumbnailsContainer.width();
    var activePosition = activeThumbnail.position().left;
    var activeWidth = activeThumbnail.outerWidth();
    if (activePosition + activeWidth > containerWidth) {
      thumbnailsContainer.scrollLeft(thumbnailsContainer.scrollLeft() + activePosition - containerWidth + activeWidth);
    } else if (activePosition < 0) {
      thumbnailsContainer.scrollLeft(thumbnailsContainer.scrollLeft() + activePosition);
    }
  }
  function updateMainImage(index) {
    var newSrc = images[index];
    $('#main-image').fadeOut(300, function () {
      $(this).attr('src', newSrc).fadeIn(300);
    });
    $('#popup-main-image').attr('src', newSrc);
    $('.thumbnails-wrapper .slider-item, .popup-thumbnails-wrapper .slider-item').removeClass('active');
    $('.thumbnails-wrapper .slider-item').eq(index).addClass('active');
    $('.popup-thumbnails-wrapper .slider-item').eq(index).addClass('active');
    updateDots(index);
    updateFraction(index);
    scrollToActiveThumbnail('.thumbnails-wrapper', '.thumbnails-container');

    // Прокрутка миниатюр в попапе
    scrollToActiveThumbnail('.popup-thumbnails-wrapper', '.popup-thumbnails-container');
  }
  $('.nav-dots').on('click', '.nav-dot', function () {
    var newIndex = $(this).data('index');
    currentIndex = newIndex;
    updateMainImage(currentIndex);
  });
  $('.thumbnails-wrapper .slider-item').on('click', function () {
    $('.thumbnails-wrapper .slider-item').removeClass('active');
    $(this).addClass('active');
    var newSrc = $(this).find('img').attr('src');
    currentIndex = images.indexOf(newSrc);
    updateMainImage(currentIndex);
  });
  $('.nav').on('click', function () {
    if ($(this).hasClass('next')) {
      currentIndex = (currentIndex + 1) % images.length;
    } else {
      currentIndex = (currentIndex - 1 + images.length) % images.length;
    }
    updateMainImage(currentIndex);
  });
  $('#main-image').on('click', function () {
    $('#popup-main-image').attr('src', $(this).attr('src'));
    $('.popup').fadeIn();
    $('.popup').addClass('active');
    updateFraction(currentIndex);
  });
  $('.close').on('click', function () {
    $('.popup').fadeOut();
    $('.popup').removeClass('active');
  });
  $('.popup').on('click', function (e) {
    if ($(e.target).is('.popup')) {
      $('.popup').fadeOut();
      $('.popup').removeClass('active');
    }
  });
  $('.popup-content').on('click', function (e) {
    e.stopPropagation();
  });
  $('.popup-nav').on('click', function () {
    if ($(this).hasClass('next')) {
      currentIndex = (currentIndex + 1) % images.length;
    } else {
      currentIndex = (currentIndex - 1 + images.length) % images.length;
    }
    $('#popup-main-image').fadeOut(300, function () {
      $(this).attr('src', images[currentIndex]).fadeIn(300);
    });
    $('.popup-thumbnails-wrapper .slider-item, .thumbnails-wrapper .slider-item').removeClass('active');
    $('.popup-thumbnails-wrapper .slider-item').eq(currentIndex).addClass('active');
    $('.thumbnails-wrapper .slider-item').eq(currentIndex).addClass('active');
    updateFraction(currentIndex);
  });

  // Прокрутка миниатюр с помощью колесика мыши
  $('.thumbnails-container, .popup-thumbnails-container').on('mousewheel', function (e) {
    this.scrollLeft -= e.originalEvent.wheelDelta;
    e.preventDefault();
  });

  // Добавляем обработчики для свайпов
  var touchStartX = 0;
  var touchEndX = 0;
  function handleSwipe() {
    if (touchEndX < touchStartX) {
      currentIndex = (currentIndex + 1) % images.length; // Swipe left
    } else if (touchEndX > touchStartX) {
      currentIndex = (currentIndex - 1 + images.length) % images.length; // Swipe right
    }

    updateMainImage(currentIndex);
  }

  // Обработчики для главного изображения
  $('#main-image').on('touchstart', function (event) {
    touchStartX = event.changedTouches[0].screenX;
  });
  $('#main-image').on('touchend', function (event) {
    touchEndX = event.changedTouches[0].screenX;
    handleSwipe();
  });

  // Обработчики для попапа
  $('#popup-main-image').on('touchstart', function (event) {
    touchStartX = event.changedTouches[0].screenX;
  });
  $('#popup-main-image').on('touchend', function (event) {
    touchEndX = event.changedTouches[0].screenX;
    handleSwipe();
  });
  createDots();
  updateFraction(currentIndex);
}
function ScrollThumbnails() {
  var isDragging = false;
  var startX;
  var scrollLeft;

  // Обрабатываем нажатие на мышь и касание
  $('.thumbnails-container, .popup-thumbnails-container').on('mousedown touchstart', function (e) {
    isDragging = true;
    $(this).addClass('active');
    startX = (e.touches ? e.touches[0].pageX : e.pageX) - $(this).offset().left;
    scrollLeft = $(this).scrollLeft();
  });

  // Обрабатываем движение мыши и касания
  $('.thumbnails-container, .popup-thumbnails-container').on('mousemove touchmove', function (e) {
    if (!isDragging) return; // Если не перетаскиваем, выходим
    e.preventDefault();
    var x = (e.touches ? e.touches[0].pageX : e.pageX) - $(this).offset().left;
    var walk = (x - startX) * 2; // Множитель для ускорения прокрутки
    $(this).scrollLeft(scrollLeft - walk);
  });

  // Остановка перетаскивания при отпускании мыши или касания
  $('.thumbnails-container, .popup-thumbnails-container').on('mouseup mouseleave touchend', function () {
    isDragging = false;
    $(this).removeClass('active');
  });
}
function GalleryPopup() {
  var popup = document.querySelector('.gallery-works-popup');
  var popupSlider = document.querySelector('.popup-slider');
  if (popupSlider) {
    var updatePopupImage = function updatePopupImage() {
      images.forEach(function (img, index) {
        img.style.display = index === currentIndex ? 'block' : 'none';
      });
    };
    var images = Array.from(popupSlider.children);
    var currentIndex = 0;
    document.querySelectorAll('.gallery-item img').forEach(function (img, index) {
      img.addEventListener('click', function () {
        currentIndex = index;
        popup.style.display = 'block';
        updatePopupImage();
      });
    });
    document.getElementById('open-popup').addEventListener('click', function () {
      popup.style.display = 'block';
      updatePopupImage();
    });
    document.querySelector('.close').addEventListener('click', function () {
      popup.style.display = 'none';
    });
    document.querySelector('.nav.next').addEventListener('click', function () {
      currentIndex = (currentIndex + 1) % images.length;
      updatePopupImage();
    });
    document.querySelector('.nav.prev').addEventListener('click', function () {
      currentIndex = (currentIndex - 1 + images.length) % images.length;
      updatePopupImage();
    });

    // Swipe functionality
    var touchStartX = 0;
    popupSlider.addEventListener('touchstart', function (e) {
      touchStartX = e.touches[0].clientX;
    });
    popupSlider.addEventListener('touchmove', function (e) {
      var touchEndX = e.touches[0].clientX;
      var diff = touchStartX - touchEndX;
      if (Math.abs(diff) > 30) {
        if (diff > 0) {
          currentIndex = (currentIndex + 1) % images.length; // swipe left
        } else {
          currentIndex = (currentIndex - 1 + images.length) % images.length; // swipe right
        }

        updatePopupImage();
      }
    });
    $('.gallery-works-popup .gallery-works-popup-inner').on('click', function (e) {
      if ($(e.target).is('.gallery-works-popup .gallery-works-popup-inner')) {
        popup.style.display = 'none';
      }
    });
    $('.gallery-works-popup .popup-content').on('click', function (e) {
      e.stopPropagation();
    });
  }
}